import { EFilterType, IMetricFilter, TMetric } from '@/typings/metric';
import { EDateGranularityType, EOriginDataType, IUser } from '.';
import { IDataset } from './dataset';
import { TDimension } from './dimension';
import {
  IAnalysisViewDimension,
  IAnalysisViewMetric,
} from '@/services/analysisView';
import { IIncrementRefreshScope } from './dataSource';
import { TFilter } from '@/components/NewFilter/types';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';
import { TMetricTime } from './analysisView';
import { TFormula } from './formula';

/**
 * 基础的加速方案配置信息
 */
export interface IAcceleratePlanBase {
  id: string;
  name: string;
  displayName: string;
  planType: EAcceleratePlanType;
  datasetName: string;
  columns: IAcceleratePlanColumn[];
  metrics: string[];
  dimensions: IAcceleratePlanDimension[];
  // 方案粒度，值是维度的 name 列表
  planGranularity?: string[];
}

/**
 * 加速方案详情，包含物化表、数据集等信息
 */
export interface IAcceleratePlanDetail extends IAcceleratePlanBase {
  owner?: IUser;
  dependencies: IAcceleratePlanDependencies;
  // 物化表数量
  mtCount: number;
}

export interface IAcceleratePlanDependencies {
  metrics: TMetric[];
  dimensions: TDimension[];
  datasets: IDataset[];
}

/**
 * DAG 图
 */
export interface ITableDag {
  nodes: Array<IPdsNode | IMtNode>;
  relations: IRelation[];
}

export interface IBaseDagNode {
  id: string;
  tableName: string;
  // 下面这些属性都是前端计算出来的
  hasMoreInput?: boolean;
  hasMoreOutput?: boolean;
  direction?: ENodeDirection; // 相对于起始节点的方向
  isHidden?: boolean; // 是否被隐藏
}

export enum ENodeDirection {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export interface IRelation {
  srcId: string;
  dstId: string;
}

// 物化表
export interface IMtNode extends IBaseDagNode {
  nodeType: EAccelerateTableType.MT;
  acceleratePlan: IAcceleratePlanBase;
  latestInstance?: ITaskInstance | null;
  columns: IMtColumnMeta[];
  planType: EAcceleratePlanType;
  isPartition: boolean;
  isFullUpdate: boolean;
  owner?: IUser;
  size: string;
  latestPartition: string; // 最新分区
}

// 物理表
export interface IPdsNode extends IBaseDagNode {
  nodeType: EAccelerateTableType.PDS;
  latestInstance?: ITaskInstance | null;
  isPartition: boolean;
  isFullUpdate: boolean;
  refreshTime: string; // 刷新的 cron 表达式
  latestPartition: string; // 最新分区
}

export enum EAccelerateTableType {
  MT = 'MT',
  PDS = 'PDS',
}

export enum EAccelerateRecommendType {
  NEW_PLAN = 'NEW_PLAN', // 新增方案
  ADD_METRIC = 'ADD_METRIC', // 增加指标
}

/**
 * 物化表
 */
export interface IMaterializedTable {
  id: string;
  name: string;
  columns: IMtColumnMeta[] | null;
  acceleratePlan?: IAcceleratePlanDetail;
  createTime: number;
  updateTime: number;
  size: string;
  owner?: IUser;
  latestInstance: ITaskInstance | null;
  // 最新数据，分区情况下是最新分区值，非分区情况下是最新数据更新的时间
  latestData?: string;
  latestScheduledInstance: ITaskInstance | null;
  isPartition: boolean;
  dataEndTime: number | null;
  dataStartTime: number | null;
}

export interface IMtColumnMeta {
  name: string;
  originDataType: EOriginDataType;
  isPartition: boolean;
  // 物化表字段对应的来源的类型
  columnType: EMtColumnType;
  // 对应的指标 code/维度 name
  aggMapping: string;
  // 对应的数据集字段
  rawMapping: IAcceleratePlanColumn;
  timeRange?: Array<{
    startTime: number;
    endTime: number;
  }>;
}

// 物化表字段对应的来源的类型
export enum EMtColumnType {
  RAW = 'RAW', // 数据集字段
  METRIC = 'METRIC', // 指标
  DIMENSION = 'DIMENSION', // 维度
}

/**
 * 补数方案
 */

export interface IManualRefreshTaskInfo {
  id: string;
  name: string;
  plan: IAcceleratePlanDetail;
  status: EManualRefreshTaskStatus;
  startPartition: string;
  endPartition: string;
  metrics: TMetric[];
}

export interface IManualRefreshTask {
  id: string;
  status: ETaskInstanceStatus;
  table: IMaterializedTable;
  startPartition: string;
  endPartition: string;
  startTime: number;
  endTime: number;
}

export interface IManualRefreshTaskWithInstance extends IManualRefreshTask {
  table: IMaterializedTable;
}

export enum EManualRefreshTaskStatus {
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

/**
 * 任务实例
 */
export interface ITaskInstance {
  id: string;
  status: ETaskInstanceStatus;
  startPartition?: string;
  endPartition?: string;
  startTime: number;
  endTime: number;
  plan: IAcceleratePlanDetail;
  sql?: string;
  log?: string;
  type: ERefreshType;
  materializedTable?: IMaterializedTable;
  waitTime: number;
}

export enum ETaskInstanceStatus {
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ERefreshType {
  MANUAL = 'MANUAL', // 补数任务
  SCHEDULED = 'SCHEDULED', // 周期任务
}

export interface IAcceleratePlanDimension {
  name: string;
  granularity?: EDateGranularityType;
}

export interface IAcceleratePlanColumn {
  datasetName: string;
  columnName: string;
}

export enum EAcceleratePlanType {
  RAW = 'RAW', // 明细字段
  AGG = 'AGG', // 指标维度
}

export interface IAccelerateResultRefreshConfig {
  lowerIncrementRefreshScope: IIncrementRefreshScope;
  upperIncrementRefreshScope: IIncrementRefreshScope;
  granularity: EDateGranularityType; // 调度周期的粒度，服务端需要，其实可以通过 cron 表达式推导出来的
  isFullRefresh: boolean; // 是否全量更新
  isScheduleUpdate: boolean;
  cron?: string;
}

export interface IAccelerateResultPlanListItem {
  id?: string;
  name: string;
  displayName: string;
  metrics: IAnalysisViewMetric[];
  dimensions: IAnalysisViewDimension[];
  refreshConfig: IAccelerateResultRefreshConfig;
  mtCount: number;
  dependencies: IAcceleratePlanDependencies;
  owner?: IUser;
}

export interface IRetryStrategy {
  retryCount: number;
  interval: number;
}

export interface ILayout {
  filter?: TNode<TFilter>;
  metricTimeFilter: TMetricTime;
}

// 应用加速方案
export interface IAccelerateResultPlan
  extends Omit<IAccelerateResultPlanListItem, 'mtCount' | 'dependencies'> {
  id?: string;
  filter?: IMetricFilter[];
  isDisplayCode: boolean; // 是否展示指标/维度编码
  isFilterOpen: boolean; // 是否开启维度筛选
  retryStrategy?: IRetryStrategy; // null代表终止
  updateNotifyConfig: {
    updateSuccessNotifyCodes?: string[];
    updateFailedNotifyCodes?: string[];
    updateDelayNotifyConfig?: {
      finishTime: string; // HH:mm:ss
      codes: string[];
    };
  };
  // 指标日期筛选
  metricTimeFilter: {
    type: EFilterType;
    formula: TFormula;
  } | null;
  layout?: ILayout;
}
