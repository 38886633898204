import {
  EColumnDataType,
  EOriginDataType,
  IUser,
  EUnit,
  EDateGranularityType,
} from '@/typings';
import { IAuthority } from './authority';
import { TFormula } from './formula';
import {
  IAtomicMetricLayout,
  ICustomDimensionGroup,
  IDerivedMetricLayout,
  IFormatConfig,
} from '@/services/metric/types';
import { ISimpleDimension, TDimension as TCommonDimension } from './dimension';
import { TSimpleCategory } from './category';
import { EDateFilterUnit, TMetricTime } from './analysisView';
import { EVizType } from '@/components/Echarts/typing';
import { EPropertyType } from './property';
import { TSimpleCategoryNode } from '@/components/CategoryTree';
import { EApprovalOperationType } from './approve';
import { PartialRequired } from '@/common/utils/type';
import {
  IFilterDependency,
  TFilterInWorkbook,
  IMetric as IWorkbookMetric,
  IDimension as IWorkbookDimension,
  ISort,
  IMetricTreeElement,
} from './workbook';
import { IAttributionAnalysisRange } from '@/pages/Metric/Detail/Content/Attribution/AttributionReport/types';

export enum EMetricType {
  // 基础指标
  ATOMIC = 'ATOMIC',
  // 派生指标
  DERIVED = 'DERIVED',
  // 复合指标
  COMPOSITE = 'COMPOSITE',
}

// 衍生类型
export enum EExtType {
  // 同环比
  SAME_PREVIOUS_PERIOD = 'SAME_PREVIOUS_PERIOD',
  // 占比
  RATIO = 'RATIO',
  // 排名
  RANK = 'RANK',
  // 累计
  ACC = 'ACC',
}

// 窗口选择
export enum EWindowChoice {
  MAX = 'MAX', // 最大值
  MIN = 'MIN', // 最小值
}

// 排名范围
export enum ERankType {
  RANK = 'RANK', // 松散排名
  RANK_DENSE = 'RANK_DENSE', // 密集排名
  ROW_NUMBER = 'ROW_NUMBER', // 连续排名
}

export enum EIsAsc {
  ASC = 'ASC', // 升序
  DESC = 'DESC', // 降序
}

// 累计类型
export enum EAccType {}

export enum EAggType {
  SUM = 'sum',
  COUNT = 'count',
  DISTINCT_COUNT = 'countDistinct',
  MAX = 'max',
  MIN = 'min',
  AVG = 'avg',
}

export enum EOffsetDirection {
  PLUS = 'PLUS', // 正向偏移
  MINUS = 'MINUS', // 反向偏移
}

export enum EGranularitySelect {
  CURRENT_DAY = 'CURRENT_DAY',
  GRANULARITY_START = 'GRANULARITY_START',
  GRANULARITY_END = 'GRANULARITY_END',
}

export enum EFilterType {
  EXPR = 'EXPR',
  RANK = 'RANK',
  FORMULA = 'FORMULA',
}

export interface IRankFilter {
  type: EFilterType.RANK;
  dimension: string;
  rank: {
    type: ERankType;
    isAsc: boolean;
  };
  aggExpr: string;
}

export interface IMetricFilter {
  type: EFilterType.FORMULA;
  formula: TFormula;
}
export type TFilter = IMetricFilter | IRankFilter;

export enum EPeriodType {
  GRAIN_TO_DATE = 'GRAIN_TO_DATE', // n（周、月、季、年）至今
  SPECIFY_DATE = 'SPECIFY_DATE', // 特殊日期-初末：日期如：本周周初、本月月初、本季度季初、本年年初、上周周末、上月月末、上季度季末、去年年末
  GRAIN_OFFSET = 'GRAIN_OFFSET', // 特殊日期-整：今日、昨日、本周、上周、本月、本季度、本年。。。
  DATE_RANGE = 'DATE_RANGE', //
  TO_DATE = 'TO_DATE', //近N天/周/月/季/年
  RELATIVE_DATE = 'RELATIVE_DATE', // 单日期
}

export interface IPeriod {
  id?: number;
  type: EPeriodType;
  typeParams: string;
}
export interface IPreAgg {
  granularity: EDateGranularityType;
  calculateType: EDerivedCalculateMode;
}
export interface IDynamicTime {
  timeGranularity: EDateGranularityType;
  offsetDirection: EOffsetDirection;
  offset: number;
  granularitySelect: EGranularitySelect;
}

export enum EPeriodGranularity {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  NEARLY_7_DAYS_INCLUDING_TODAY = 'NEARLY_7_DAYS_INCLUDING_TODAY',
  NEARLY_7_DAYS_EXCLUDING_TODAY = 'NEARLY_7_DAYS_EXCLUDING_TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_QUARTER = 'LAST_QUARTER',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}

export enum EPeriodFormType {
  // 近1日
  NEARLY_1_DAYS = 'NEARLY_1_DAYS',
  // 近7日
  NEARLY_7_DAYS = 'NEARLY_7_DAYS',
  // 近30日
  NEARLY_30_DAYS = 'NEARLY_30_DAYS',
  // 近180日
  NEARLY_180_DAYS = 'NEARLY_180_DAYS',
  // 近365日
  NEARLY_365_DAYS = 'NEARLY_365_DAYS',
  // 本周
  CURRENT_WEEK = 'CURRENT_WEEK',
  // 本月
  CURRENT_MONTH = 'CURRENT_MONTH',
  // 本季度
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  // 本年
  CURRENT_YEAR = 'CURRENT_YEAR',
  // 本周至今
  CURRENT_WEEK_TO_DATE = 'CURRENT_WEEK_TO_DATE',
  // 本月至今
  CURRENT_MONTH_TO_DATE = 'CURRENT_MONTH_TO_DATE',
  // 本季度至今
  CURRENT_QUARTER_TO_DATE = 'CURRENT_QUARTER_TO_DATE',
  // 本年至今
  CURRENT_YEAR_TO_DATE = 'CURRENT_YEAR_TO_DATE',
  // 本周周初
  CURRENT_WEEK_START = 'CURRENT_WEEK_START',
  // 上周周末
  LAST_WEEK_END = 'LAST_WEEK_END',
  // 本月月初
  CURRENT_MONTH_START = 'CURRENT_MONTH_START',
  // 上月月末
  LAST_MONTH_END = 'LAST_MONTH_END',
  // 本年年初
  CURRENT_YEAR_START = 'CURRENT_YEAR_START',
  // 上年年末
  LAST_YEAR_END = 'LAST_YEAR_END',
  // 历史至今
  HISTORY_TO_DATE = 'HISTORY_TO_DATE',
}

// 不可累加维度
export interface INonAdditiveDimension {
  // 维度列
  dimensions: string[];
  // 窗口选择
  windowChoice: EWindowChoice;
  // 窗口分组列
  windowGroupings?: string[];
}

export enum EMetricOwnerType {
  // 业务负责人
  BIZ_OWNER = 'BIZ_OWNER',
}

interface IMetricOwner {
  type: EMetricOwnerType;
  userModel?: IUser;
}

export enum EOnlineStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}
export interface IMetricProperties {
  [EPropertyType.BASE]: {
    // 指标名称
    metricName: {
      value: string;
    };
    // 指标编码
    metricCode: {
      value: string;
    };
    // 展示名
    metricDisplayName: {
      value: string;
    };
    unit: {
      value?: EUnit;
    };
    businessOwner: {
      value: {
        type: EMetricOwnerType;
        userId: string;
      };
    };
    [key: string]: {
      value?: unknown;
    };
  };
  [EPropertyType.BUSINESS]: {
    businessCaliber: {
      value?: string;
    };
    metricCategoryId: {
      value: Array<TSimpleCategoryNode>;
    };
    [key: string]: {
      value?: unknown;
    };
  };
  [EPropertyType.MANAGE]: {
    [key: string]: {
      value?: unknown;
    };
  };
  [EPropertyType.TECHNOLOGY]: {
    [key: string]: {
      value?: unknown;
    };
  };
}
export interface IMetric {
  // 指标名称
  name: string;
  // 指标名称
  metricName: string;
  // 指标编码
  metricCode: string;
  // 指标code
  code: string;
  // 业务口径
  description: string;
  // 负责人
  owners: IMetricOwner[];
  // 创建时间
  gmtCreate: number;
  // 修改时间
  gmtUpdate: number;
  // 指标数据类型
  originDataType: EOriginDataType;
  // 编辑人
  updater?: IUser;
  // 产品层类型
  dataType: EColumnDataType;
  // 已收藏
  collect?: boolean;
  // 收藏数量
  collectCount?: number;
  authority?: IAuthority;
  owner?: IUser;
  category: Array<TSimpleCategory>;
  // 单位 默认其他
  unit: EUnit;
  // 查询量
  queryCount?: number;
  // 浏览量
  viewDetailCount?: number;
  // 预览配置
  previewConfig?: IMetricPreviewConfig;
  // 归因树配置
  attributionTree?: IMetricAttributionTree;
  // 归因报告配置
  attributionReport?: IMetricAttributionReport;
  // 版本信息
  version: number;
  // 是否发布
  isPublished: boolean;
  // 指标状态： 上线/下线
  status: EOnlineStatus;
  hasDraft: boolean;
  // 属性
  properties: IMetricProperties;
  approvalMutationStatus?: EApprovalOperationType; // 正在进行的审批操作
  approvalAuthorityStatus?: EApprovalOperationType.GRANT_AUTH; // 审批权限操作
  // 是否启用新维度
  isNewDimensionDefaultEnable: boolean;
  publicDimension: {
    // 不可用维度列表
    disableDimensions: Pick<
      ISimpleDimension,
      'name' | 'displayName' | 'originDataType'
    >[];
    // 可用的维度列表
    availableDimensions: Pick<
      ISimpleDimension,
      'name' | 'displayName' | 'originDataType'
    >[];
  };
  // 可用的维度列表（模型关系列表）
  availableDimensions: ISimpleDimension[];
}

export interface IMetricPreviewConfig {
  layout: IMetricPreviewDocument;
  dependencies: {
    dimensions: TCommonDimension[];
    metrics: TMetric[];
  };
}

// 旧的预览配置，只用作兼容老数据
export interface IMetricPreviewDocumentV0 {
  // 格式化配置
  formatConfig?: IFormatConfig;
  // 指标日期
  metricTime: {
    select: EDateFilterUnit;
  };
  // 维度
  dimensionMap: {
    [key: string]: {
      def: {
        dimensionName: string;
      };
      granularity?: EDateGranularityType;
    };
  };
  // 图表类型
  chartType: TChartType;
}

export type TChartType = EVizType | 'TABLE';

export interface IMetricPreviewDocument {
  dimensionsMap: Record<string, TDimension>; // IDimension 复用分析簿的 IDimension
  metricsMap: Record<string, IWorkbookMetric>; // IMetric 复用分析簿的 IMetric
  dimensions: string[];
  metrics: string[];
  filters?: TFilterInWorkbook[]; // 筛选的结构和分析簿一致
  filterDependency: Record<string, IFilterDependency>; // 筛选依赖的结构和分析簿一致
  metricTimeFilter: TMetricTime; // 指标日期筛选
  chartType: TChartType;
  sorts: ISort[];
  // 前端的版本号，用于判断是否是新的预览配置结构
  version: number;
}

export type TDimension = Omit<IWorkbookDimension, 'sort'> & {
  isLocked?: boolean;
};

export interface IMetricPreviewConfigReq
  extends Omit<IMetricPreviewConfig, 'dependencies'> {
  dependencies: {
    dimensions: string[];
    metrics: string[];
  };
  code: string;
}

export interface IMetricAttributionTree {
  layout: IMetricAttributionTreeDocument;
  dependencies: {
    dimensions: TCommonDimension[];
    metrics: TMetric[];
  };
}

export interface IMetricAttributionTreeReq
  extends Omit<IMetricAttributionTree, 'dependencies'> {
  dependencies: {
    dimensions: string[];
    metrics: string[];
  };
  code: string;
}

export interface IMetricAttributionReportDocument {
  // 分析范围
  analysisRange: IAttributionAnalysisRange;
  // 已选的维度
  dimensions: Array<{
    name: string;
    granularity?: EDateGranularityType;
  }>;
}

export interface IMetricAttributionReport {
  layout: IMetricAttributionReportDocument;
  dependencies: {
    dimensions: TCommonDimension[];
    metrics: TMetric[];
  };
}

export interface IMetricAttributionReportReq
  extends Omit<IMetricAttributionReport, 'dependencies'> {
  dependencies: {
    dimensions: string[];
    metrics: string[];
  };
  code: string;
}

/**
 * 归因分析树的数据结构
 */
export interface IMetricAttributionTreeDocument
  extends Pick<
    IMetricTreeElement,
    'metricsMap' | 'subMetrics' | 'treeNodes' | 'filterDependency' | 'filters'
  > {
  // 分析范围
  analysisRange: IAttributionAnalysisRange;
  mainMetric: string;
}

export interface IAtomicCaliber {
  // 数据集
  datasetName: string;
  // 聚合表达式
  formula: TFormula;
  // 不可累加维度开关
  enableNonAdditiveDimensions: boolean;
  // 不可累加维度
  nonAdditiveDimensions?: INonAdditiveDimension;
  // 筛选器
  filters?: TFilter[];
  // 统计日期
  metricTime?: string;
  // 指标日期标识的类型
  metricTimeDataType?: EOriginDataType;
}

export interface IAtomicBasicMetric extends IMetric {
  // 基础指标的类型
  type: EMetricType.ATOMIC;
  // 指标口径
  caliber: IAtomicCaliber;
  layout: IAtomicMetricLayout;
}

export enum EDerivedCalculateType {
  // 时段汇总
  ALL_CALCULATE = 'ALL_CALCULATE',
  // 移动计算
  MOVING_CALCULATE = 'MOVING_CALCULATE',
}
export enum EDerivedCalculateMode {
  // 峰值
  MAX = 'MAX',
  // 谷值
  MIN = 'MIN',
  // 均值
  AVG = 'AVG',
}
export enum EDerivedIndirectionType {
  // 同环比
  SAME_PERIOD = 'SAME_PERIOD',
  // 排名
  RANK = 'RANK',
  // 占比
  PROPORTION = 'PROPORTION',
  // 自定义聚合
  CUSTOM_AGG = 'CUSTOM_AGG',
}
export enum EDerivedSamePeriodType {
  // 同比增长率
  VALUE = 'VALUE',
  // 同比增长值
  GROWTH_VALUE = 'GROWTH_VALUE',
  // 同比增长率
  GROWTH = 'GROWTH',
}
export enum EDerivedRankType {
  // 松散排名
  RANK = 'RANK',
  // 密集排名
  RANK_DENSE = 'RANK_DENSE',
  // 连续排名
  ROW_NUMBER = 'ROW_NUMBER',
}
export interface IExtIndirectionSamePeriod {
  // 同环比类型
  type: EDerivedIndirectionType.SAME_PERIOD;
  // 同环比类型
  samePeriodType: EDerivedSamePeriodType;
  offset: ISamePeriodOffset;
}

export interface ISamePeriodOffset {
  granularity: EDateGranularityType;
  offset: number;
  specifyDateInPeriod?: ESpecifyDateInPeriod;
  dateTag?: string;
}

// 期初期末
export enum ESpecifyDateInPeriod {
  START = 'START',
  END = 'END',
}

export interface IExtIndirectionRank {
  // 排名类型
  type: EDerivedIndirectionType.RANK;
  // 排名范围
  rankRanges?: string[];
  // 排名维度
  rankDimensions?: string[];
  // 排名方法
  rankType: EDerivedRankType;
  // 是否升序
  asc: boolean;
}

export interface IExtIndirectionProportion {
  // 占比类型
  type: EDerivedIndirectionType.PROPORTION;
  // 占比范围
  proportionRanges?: string[];
  // 占比维度
  proportionDimensions?: string[];
}
export interface IExtIndirectionCustomAgg {
  // 自定义聚合类型
  type: EDerivedIndirectionType.CUSTOM_AGG;
  // 自定义聚合维度
  customDimensionGroups: ICustomDimensionGroup[];
}
export type TExtIndirection =
  | IExtIndirectionSamePeriod
  | IExtIndirectionRank
  | IExtIndirectionProportion
  | IExtIndirectionCustomAgg;

export interface IExtMode {
  granularity: EDateGranularityType;
  calculateType: EDerivedCalculateMode;
}
export interface IExtValue {
  isCustomDimension: boolean;
  extMode?: IExtMode;
}
export interface IDerivedMetricCaliber {
  // 引用指标
  refMetricCode: string;
  calculateType: EDerivedCalculateType;
  period?: IPeriod;
  preAggs?: IPreAgg[];
  indirection?: TExtIndirection;
  // 衍生方式
  extMode?: IExtMode;
  customDimensionGroups?: ICustomDimensionGroup[];
  movingCalculate?: {
    // 移动计算周期
    granularity: EDateGranularityType;
    aggType: EAggType;
  };
  filters?: TFilter[];
}

export interface IDerivedMetric extends IMetric {
  type: EMetricType.DERIVED;
  // 指标口径
  caliber: IDerivedMetricCaliber;
  layout: IDerivedMetricLayout;
}

export interface ICompositeMetricCaliber {
  // 复合表达式
  formula: TFormula;
}

export interface ICompositionMetric extends IMetric {
  type: EMetricType.COMPOSITE;
  // 复合指标
  atomicComposite: boolean;
  // 指标口径
  caliber: ICompositeMetricCaliber;
  // 因子维度列表
  availableFactorDimensions: Pick<
    ISimpleDimension,
    'name' | 'displayName' | 'originDataType'
  >[];
}

export type TMetric = IAtomicBasicMetric | IDerivedMetric | ICompositionMetric;

// 根据IAtomicBasicMetric 、 IDerivedMetric 、 ICompositionMetric定义一个联合类型，指标的名称 、指标名称 、指标类型、code是必定有的，其他属性可选，

export type TAtomicBasicMetricDraft = PartialRequired<
  IAtomicBasicMetric,
  'name' | 'metricName' | 'metricCode' | 'code' | 'type'
>;

export type TDerivedMetricDraft = PartialRequired<
  IDerivedMetric,
  'name' | 'metricName' | 'metricCode' | 'code' | 'type'
>;

export type TCompositionMetricDraft = PartialRequired<
  ICompositionMetric,
  'name' | 'metricName' | 'metricCode' | 'code' | 'type'
>;

export type TMetricDraft =
  | TAtomicBasicMetricDraft
  | TDerivedMetricDraft
  | TCompositionMetricDraft;
