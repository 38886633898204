import AIR from './AIR';
import STARROCKS from './STARROCKS';
import DORIS from './DORIS';
import DATABRICKS from './DATABRICKS';
const functionConfig = {
  AIR,
  STARROCKS,
  DORIS,
  DATABRICKS,
};

export default functionConfig;
