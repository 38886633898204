import t from '@/locales';
import { EDateGranularityType } from '@/typings';
import { EQuickCalcType } from '@/typings/analysisView';
// react-dnd 拖放类型
export const DND_TYPE_ADD_METRIC = 'DND_TYPE_ADD_METRIC';
export const DND_TYPE_ADD_DIMENSION = 'DND_TYPE_ADD_DIMENSION';
export const DND_TYPE_ADD_DATASET = 'DND_TYPE_ADD_DATASET';
export const DND_TYPE_ADD_ANALYSIS_VIEW = 'DND_TYPE_ADD_ANALYSIS_VIEW';
export const DND_TYPE_PLACE_DIMENSION = 'DND_TYPE_PLACE_DIMENSION';
export const DND_TYPE_PLACE_METRIC = 'DND_TYPE_PLACE_METRIC';
// 筛选组件高度
export const FILTER_ELEMENT_HEIGHT = 80; // 由于画布中的高度不是准确的 px，是粗略估计值
export const FILTER_ELEMENT_HEIGHT_WITHOUT_TITLE = 32; // 由于画布中的高度不是准确的 px，是粗略估计值
// 画布宽度，单位不是 px，而是布局组件的单位
export const CANVAS_WIDTH = 1280;
const FILTER_ELEMENT_WIDTH_RATIO = 3;
export const DEFAULT_FILTER_ELEMENT_WIDTH =
  CANVAS_WIDTH / FILTER_ELEMENT_WIDTH_RATIO;

// 衍生方式名称
export const calcTypeTitleMap = {
  [EQuickCalcType.GROWTH]: {
    other: t.workbook.workbook.config.metric.periodicComparison.growth.other,
    day: t.workbook.workbook.config.metric.periodicComparison.growth.day,
  },
  [EQuickCalcType.GROWTH_VALUE]: {
    other:
      t.workbook.workbook.config.metric.periodicComparison.growthValue.other,
    day: t.workbook.workbook.config.metric.periodicComparison.growthValue.day,
  },
  [EQuickCalcType.VALUE]: {
    other: t.workbook.workbook.config.metric.periodicComparison.value.other,
    day: t.workbook.workbook.config.metric.periodicComparison.value.day,
  },
  [EQuickCalcType.RANKING]: {
    other: t.workbook.workbook.config.metric.ranking.default,
    day: t.workbook.workbook.config.metric.ranking.default,
  },
  [EQuickCalcType.PROPORTION]: {
    other: t.workbook.workbook.config.metric.proportion.default,
    day: t.workbook.workbook.config.metric.proportion.default,
  },
};
export const getCalcTypeTitle = (
  calcType: EQuickCalcType,
  granularity?: EDateGranularityType,
) => {
  const titleMap = calcTypeTitleMap[calcType];
  if (granularity === EDateGranularityType.DAY) {
    return titleMap.day;
  }
  return titleMap.other;
};
