export const metric = {
  list: {
    batch: {
      adjustCategory: (value: string) => {
        return `指标 ${value} 无调整类目权限`;
      },
      transfer: (value: string) => {
        return `指标 ${value} 无转交权限`;
      },
    },
    create: '新建指标',
    metric: '指标',
    displayName: '展示名',
    dataOwner: '负责人',
    category: {
      select: {
        title: '全部指标',
      },
    },
    type: '类型',
    status: '状态',
    dataset: '数据集',
    menu: {
      view: '查看',
      share: '分享',
      copy: '复制创建指标',
      collect: {
        default: '收藏',
        success: '收藏成功',
      },
      cancelCollect: {
        default: '取消收藏',
        success: '取消收藏成功',
      },
      delete: '删除',
      editCategory: {
        default: '调整类目',
        success: '调整类目成功',
      },
      applyAdmin: '申请管理员权限',
      applySuccess: '申请成功',
      category: '类目',
      edit: '编辑',
      transfer: '转交',
    },
    number: ({ total }: { total: string | number }) => `${total}个指标`,
    empty: '没找到任何内容，快来创建指标吧',
    operateSuccess: '操作成功',
    deleteSuccess: '删除成功',
  },
  detail: {
    overview: {
      preview: '数据预览',
      default: '概览',
      tableChart: '表格',
      saveConfig: '保存为默认配置',
      saveConfigSuccess: '保存成功',
      noPermission: '无权查看，请向管理员申请权限',
      notSupportPreview: '该指标暂不支持预览',
    },
    attribution: {
      dimensionAttribution: '多维度归因',
      treeAttribution: '指标树归因',
      save: '设为默认配置',
      saveSuccess: '已保存',
      mainMetric: '主指标:',
      subMetrics: '拆解指标:',
      editRelation: '编辑指标关系',
      filters: '筛选:',
      default: '归因',
      analysisDate: '分析日期:',
      comparisonType: '对比方式',
      comparisonDate: '对比日期:',
      analysisDimension: '分析维度:',
      seeResult: '查看归因结果',
      dayOverDay: '日环比',
      weekOverWeek: '周同比',
      monthOverMonth: '月同比',
      quarterOverQuarter: '季同比',
      yearOverYear: '年同比',
      customized: '自定义',
      analysisReport: '分析报告',
      overallContributionRate: '贡献率',
      growthRate: '增长率',
      topInfluenceFactors: (limit: number) => `Top${limit}影响因素为：`,
      be: '为',
      guideTip: '选择分析维度，查看不同维度对指标的结果影响。',
      pleaseSelectDimension: '请选择分析维度',
      currentValue: '当前值',
      comparisonValue: '对比值',
      comparisonRate: '对比率',
      growth: '增长值',
      dimensionValueLimitTip: '仅展示前200个维度值',
      compare: '对比',
      cancel: '取消',
      dataLoading: '数据加载中...',
      dimensionValue: '维度值',
    },
    preview: {
      config: {
        label: {
          timeRange: '时间范围:',
          metric: '指标:',
          dimension: '维度:',
          filter: '筛选:',
        },
        add: '添加',
      },
      title: '数据预览',
      sort: '排序',
      downloadFileName: (metricName: string) => `${metricName}_预览数据`,
    },
    definition: '定义',
    authority: {
      default: '权限',
      use: '使用权限',
      rowFilter: {
        emptyDesc: '未配置行权限规则',
        title: '行权限规则',
        mode: '模式',
        rule: '规则',
        edit: '配置规则',
        conditionalRule: '条件组合',
        tableRule: '权限表匹配',
        default: '行级权限',
        dimensionRelated: '关联维度',
        manageRules: '配置规则',
        addRules: '添加规则',
        manageRulesSuccess: '配置规则成功',

        table: {
          ruleName: '规则名称',
          dimension: '关联维度',
          restrictedUsers: '受限用户',
          rule: {
            default: '可见内容',
            desc: '同时满足以下条件',
          },
          operate: {
            title: '操作',
            edit: '编辑',
            delete: '删除',
          },
        },
        createModal: {
          rule: '规则',
          ruleName: '规则名称',
          users: '受限用户',
          rules: '可见内容',
          specifyDimension: '指定维度',
          whitelists: '白名单',
          necessary: ({ name }: { name: string | number }) => `${name}不能为空`,
          name: '规则名称',
          authorityTable: '权限表',
          userColumn: '用户名标识字段',
          tableColumn: '权限表字段',
          setFilter: '设置过滤条件',
          filterDimensionDesc: '查询中包含指定维度时，过滤条件生效',
          type: '条件关系',
          and: '且',
          or: '或',
          dimension: '指标维度',
          authorityTableColumn: '权限表字段',
          authorityTableColumnSelect: '选择字段',
          delete: '删除',
          addFilter: '添加过滤关联',
          dimensionFilter: {
            flagDesc: '查询中包含指定维度时，过滤条件生效',
            tooltip: {
              includes: '查询中包含选中的维度时，该条行权限过滤生效',
              excludes: '查询中使用排除维度以外的维度时，该条行权限过滤生效',
            },
            flagDescSmall: '指定维度',
            includes: '包含',
            excludes: '排除',
          },
        },
      },
      username: '用户',
      role: '权限',
      addAuth: '授权',
      authUserAndUserGroup: '授权用户/用户组',
    },
    dimension: {
      default: '维度',
      count: ({ count }: { count: string | number }) => `${count} 个维度`,
      displayName: '展示名',
      code: '维度编码',
      name: '名称',
      description: '描述',
      datatype: '类型',
      category: '类目',
      owner: '负责人',
    },
    lineage: '血缘',
    version: {
      default: '版本',
      title: '版本',
      owner: '创建人',
      createTime: '创建时间',
      operate: {
        title: '操作',
        view: '查看',
        delete: '删除',
      },
      status: {
        draft: '草稿',
        onLine: '线上版本',
        current: '当前版本',
        unPublish: '未发布',
      },
      delete: {
        title: '删除草稿',
        content: '从系统中删除草稿。此草稿被删除后将无法恢复',
        ok: '删除',
        cancel: '取消',
      },
      infoView: {
        close: '关闭',
        edit: '编辑',
        publishDraft: '发布草稿至线上',
      },
    },
    monitor: {
      title: '监控',
      count: (number: number) => `${number}个监控`,
      add: '新建监控',
      table: {
        name: '名称',
        status: '状态',
        time: '检测时间',
        owner: '负责人',
        operate: {
          title: '操作',
          view: '查看',
          edit: '编辑',
          delete: '删除',
        },
        deleteModal: {
          title: '删除监控',
          deleteDesc: '监控删除后不可恢复，是否删除？',
          deleteSuccess: '删除成功',
        },
      },
    },
    edit: '编辑',
    editDraft: '编辑草稿',
    share: '分享',
    favCount: '收藏量',
    queryCount: '查询量',
    viewCount: '浏览量',
    countDesc: '统计近7日(含今日)的数据',
    publish: '发布上线',
    setOnLine: '上线',
  },
  define: {
    ownerAuthorityAlert: {
      start: '您正在使用指标负责人「',
      end: '」的权限进行指标编辑',
    },
    label: {
      metricName: '指标展示名',
      metricCode: '指标名称',
      bizOwner: '业务负责人',
      description: '业务口径',
      category: '类目',
      analysisDimension: '分析维度',
      unit: {
        default: '单位',
        currency: {
          default: '货币单位',
          cnyFen: '分（人民币）',
          cnyYuan: '元（人民币）',
          cnyWan: '万元（人民币）',
          cnyBaiWan: '百万元（人民币）',
          cnyYiYuan: '亿元（人民币）',
          usdCent: '分（美元）',
          usdDollar: '元（美元）',
          eurEuro: '元（欧元）',
          hkdDollar: '元（港币）',
          newTaiwanDollar: '元（新台币）',
        },
        time: {
          default: '时间单位',
          day: '日',
          month: '月',
          week: '周',
          year: '年',
          hour: '小时',
          minute: '分钟',
          second: '秒',
          quarter: '季',
          millisecond: '毫秒',
        },
        ratio: {
          default: '比例单位',
          decimal: '小数',
          percentage: '百分位数',
          permille: '千分位数',
        },
        noun: {
          default: '名次',
          rank: '排名',
        },
        objectQuantity: {
          default: '对象量词',
          household: '户',
          transaction: '笔',
          item: '件',
          individual: '个',
          occurrence: '次',
          personDay: '人日',
          family: '家',
          hand: '手',
          sheet: '张',
          package: '包',
        },
        weight: {
          default: '重量单位',
          ton: '吨',
          kilogram: '公斤',
        },
        other: '其他',
      },
      dataset: '数据集',
      statisticalMethod: '统计方式',
      halfAdditiveDimensions: '半累加指标',
      metricTime: '指标日期标识',
      nonAdditiveDimensions: '不可累加维度',
      atomicAndComposition: '基础/复合指标',
      windowGrouping: '窗口分组',
      windowChoice: '窗口选择',
      atomic: '基础指标',
      extType: '衍生类型',
      statisticalPeriod: '时间限定',
      dimensionFilter: '业务限定',
      extMode: '衍生方式',
      filter: '筛选',
      addDimension: '添加字段',
      expr: '复合表达式',
    },
    help: {
      metricCode:
        '指标的唯一名称，创建后不支持修改，最多50个字符，支持英文数字以及下划线。',
      windowGrouping:
        '计算指标聚合时，会预先将数据按照窗口分组的字段进行分组，在分组后的数据内根据窗口选择进行数据的过滤。',
    },
    message: {
      categoryRequired: '请选择类目',
      metricNameRequired: '请输入指标展示名',
      metricCodeRequired: '请输入指标名称',
      bizOwnerIdRequired: '请选择业务负责人',
      metricCodePattern: '指标名称只能包含字母、数字、下划线',
      datasetRequired: '请选择数据集',
      aggExprRequired: '请选择聚合字段',
      metricTimeRequired: '请选择指标日期标识',
      datasetMissed: '数据集无法使用',
      columnMissed: '字段不存在',
      metricTimeMissed: '指标日期标识字段已被删除',
      unExitColumn: '字段被删除或已不是维度字段',
      exprError: '表达式错误',
      noDateColumn: '不可用原因：数据集无日期类型字段',
    },
    title: {
      basicInfo: '基础信息',
      calculateCaliber: '计算逻辑',
      sql: '指标 SQL',
      dimension: '分析维度',
      disabledDesc: '以下维度为该指标的禁用维度 (黑名单模式)',
      availableDesc: '以下维度为该指标的可用维度 (白名单模式)',
      partDimensionDesc: '以下维度对部分指标生效',
      disabledEmpty: '无禁用维度',
      availableEmpty: '无可用维度',
    },
    metricPicker: {
      disableReason: '只能使用基础指标和由基础指标生成的复合指标',
      cannotRelySelf: '不能依赖指标自身',
    },
    sql: {
      desc: '展示查看指标日期为今日的指标结果对应的 SQL 代码',
      loading: '获取 SQL 代码中...',
      loadFailed: '获取 SQL 代码失败',
      copySuccess: '复制成功',
    },
    agg: {
      sum: '求和',
      count: '计数',
      distinctCount: '去重计数',
      max: '最大值',
      min: '最小值',
      avg: '平均值',
    },
    radio: {
      normal: '常规',
      customExpr: '自定义表达式',
    },
    businessLimit: {
      des: '同时满足以下条件',
    },
    placeholder: {
      metricTimeWithoutTime: '该数据集没有日期类型字段',
    },
    windowChoice: {
      max: '最大值',
      min: '最小值',
    },
    extType: {
      samePreviewsPeriod: '同/环比',
      ratio: '占比',
      rank: '排名',
      acc: '累计',
    },
    dimension: {
      disableReason: '已在分析维度中',
      allCanUse: '所有指标可用',
      onlyCanUse: (text: string) => `仅 ${text} 可用`,
      newDimensionDefaultEnableTip:
        '数据模型发生变更后，可能导致指标新增可用维度，该维度会自动置为启用状态',
      foldUp: '收起',
      unfold: '更多',
      add: '添加',
      newDimensionDefaultEnable: '新增维度默认为启用状态',
      placeholder: {
        atomic: '选择数据集后查看维度',
        derived: '选择基础/复合指标后查看维度',
        composition: '创建复合表达式后查看维度',
      },
    },
    dynamic: {
      currentTime: '当前时间',
      de: '的',
      plus: '增加',
      minus: '减少',
    },
    timeGranularity: {
      day: '日',
      week: '周',
      month: '月',
      quarter: '季',
      year: '年',
      minute: '分',
      hour: '小时',
      second: '秒',
    },
    granularitySelect: {
      currentDay: '当日',
      granularityStart: ({ granularity }: { granularity: string | number }) =>
        `所在${granularity}${granularity}初`,
      granularityEnd: ({ granularity }: { granularity: string | number }) =>
        `所在${granularity}${granularity}末`,
    },
    modal: {
      title: {
        create: '创建指标',
        edit: '编辑指标',
        draft: '编辑指标草稿',
      },
      ok: {
        isEdit: '保存草稿',
        notEdit: '保存草稿',
      },
      sameMetric: {
        title: '重复指标',
        tip: '已有指标与你创建的指标口径一致，是否继续发布？',
        ok: '继续发布',
        cancel: '取消发布',
      },
      publish: '保存并发布',
      selectMetricType: '指标类型',
    },
    metric: {
      text: {
        atomic: '基础指标',
        derived: '派生指标',
        composition: '复合指标',
      },
    },
    indirection: {
      mode: {
        default: '衍生方法',
        samePreviewsPeriod: {
          selectPlaceholder: '请选择',
          default: '同环比',
          value: '值',
          growthValue: '增长值',
          growth: '增长率',
          seq: '环比',
          pop: '同比',
          lastWeekEnd: '上周周末',
          lastMonthEnd: '上月月末',
          lastQuarterEnd: '上季度末',
          lastYearEnd: '去年年末',
          calendarDay: '自然日',
          customPeriodModal: {
            title: '自定义同环比',
            type: '计算类型',
            relativeTime: '相对时间',
          },
          before: '过去',
          after: '未来',
          displayName: (
            relativeTime: string,
            typeName: string,
            overName: string,
            dateTagName: string,
          ) =>
            `${relativeTime}${overName}${typeName}${
              dateTagName ? `（${dateTagName}）` : ''
            }`,
        },
        proportion: '占比',
        rank: '排名',
        customAgg: '自定义聚合',
      },
      rank: {
        dimension: '排名维度',
        range: '排名范围',
        method: {
          default: '排名方法',
          dense: 'RankDense（1,1,2,3）',
          sparse: ' Rank（1,1,3,4）',
          rowNumber: 'RowNumber（1,2,3,4）',
          des: '升序（从低到高）',
          asc: '降序（从高到低）',
        },
      },
      proportion: {
        dimension: '占比维度',
        range: '占比范围',
      },
    },
    period: {
      today: '今日',
      yesterday: '昨日',
      nearly1days: '近1日',
      nearly7days: '近7日',
      nearly30days: '近30日',
      nearly180days: '近180日',
      nearly365days: '近365日',
      nearly3years: '近3年',
      currentWeek: '本周',
      lastWeek: '上周',
      week: {
        last: ({ number }: { number: string | number }) => `近${number}周`,
      },
      currentMonth: '本月',
      lastMonth: '上月',
      month: {
        last: ({ number }: { number: string | number }) => `近${number}月`,
      },
      currentQuarter: '本季度',
      lastQuarter: '上季度',
      quarter: {
        last: ({ number }: { number: string | number }) => `近${number}季度`,
      },
      currentYear: '本年',
      currentWeekToDate: '本周至今',
      currentMonthToDate: '本月至今',
      currentQuarterToDate: '本季度至今',
      currentYearToDate: '本年至今',
      currentWeekStart: '本周周初',
      lastWeekEnd: '上周周末',
      currentMonthStart: '本月月初',
      lastMonthEnd: '上月月末',
      currentYearStart: '本年年初',
      lastYearEnd: '去年年末',
      historyToDate: '历史至今',
      customOption: '自定义',
      inValidTimeValue: '时间粒度不能与结束时间的粒度不一致',
      type: {
        recently: '近N日/近N周...',
        toDate: '周初至今/月初至今...',
        special: '昨日/本周/本月月初...',
      },
      calcType: {
        all: '整体',
      },
      customLimit: {
        title: '自定义时间限定组合',
        accordingTo: '按',
        calc: '计算',
        per: '每',
        result: '的指标结果，',
      },
      custom: '自定义',
    },
    extMode: {
      avg: '均值',
      max: '峰值',
      min: '谷值',
      customDimensionGroups: {
        add: '添加分组聚合',
        max: '最大值',
        min: '最小值',
      },
      validate: {
        noValue: '请选择衍生方式',
        customAgg: {
          noDimension: '请添加自定义聚合方式',
          incomplete: '请填写完整的自定义聚合方式',
        },
        samePeriod: {
          noDimension: '请选择同环比',
        },
        granularityChange: '时间粒度发生了变化，请重新选择衍生方式同环比',
      },
    },
    derivativeMode: {
      customDimensionGroups: {
        base: '以',
        result: '结果的',
        firstRowGroup: '作为维度，计算指标在该维度下的指标结果。',
        otherRowGroup: '作为维度，在分析维度的组合内，计算',
        lastRowGroup: '在分析维度的组合内，计算',
      },
    },
    rank: {
      rank: '松散排名',
      rankDense: '密集排名',
      rankNumber: '连续排名',
    },
    expr: {
      required: '请输入表达式',
      error: '表达式校验错误',
      useExpression: '输入计算表达式，例如：[字段1] + [字段2]',
      filter: '输入筛选表达式，例如：[字段1] IN “值1,值2” OR [字段2] > “值3”',
    },
    validate: {
      atomic: '请选择基础指标',
      atomicNotExist: '基础指标已被删除',
      mustChooseOne: '「时间限定」、「业务限定」或「衍生方式」必填一项',
      filterConfig: '请填写完整筛选器内容',
    },
    edit: {
      placeholder:
        '输入表达式计算维度值，如:\nIF( SUM([订单金额]) > 1000,“高”,“低” )',
      placeholder1: '输入筛选表达式，例如：IN([字段],"值1,值2") OR [字段2]>值3',
    },
    metricTimeHelper: {
      partitionText: '分区',
      desc1: '与分区字段',
      desc2: '未开启分区映射，开启后可提升查询速度。',
      setBtnText: '设置分区映射',
    },
  },
  definition: {
    label: {
      dataset: '数据集',
      aggExpr: '统计方式',
      metricTime: '指标日期标识:',
      halfAdditiveDimensions: '维度',
      agg: '聚合',
      filter: '筛选',
      businessFilter: '业务限定',
      windowGrouping: '窗口分组',
      windowChoice: '窗口选择',
      nonAdditiveDimensions: '不可累加维度:',
      atomic: '基础/复合指标',
      statistics: '时间限定',
      extMode: '衍生方式',
      expr: '复合表达式',
    },
    filter: {
      endWith: ({ text }: { text: string | number }) => `以  ${text}  结尾`,
      notEndWith: ({ text }: { text: string | number }) =>
        `不以  ${text}  结尾`,
      startWith: ({ text }: { text: string | number }) => `以  ${text}  开头`,
      notStartWith: ({ text }: { text: string | number }) =>
        `不以  ${text}  开头`,
      contain: '包含',
      notContain: '不包含',
      equal: '等于',
      infinitesimal: '无穷小',
      infinity: '无穷大',
      notEqual: '不等于',
      date: {
        range: {
          all: '任意时间段',
          before: '早于',
          after: '晚于',
          specify: '指定日期',
        },
      },
    },
  },
  create: {
    title: '新建指标',
    cancel: '取消',
    helpDocument: '帮助手册',
    desc: {
      atomic: '基于业务度量字段进行聚合，如：交易金额',
      derived:
        '基于基础指标、时间限定、业务限定和衍生方式定义的指标，如：近7日订单总额',
      composition:
        '多个基础指标或派生指标的四则运算，如：人均交易额=交易金额/交易用户数',
    },
  },
  edit: {
    title: '修改基础信息',
    restoreOnlineConfiguration: '还原为线上配置',
  },
  save: {
    edit: {
      success: '保存成功',
    },
    create: {
      success: '创建成功',
    },
  },
  common: {
    input: {
      placeholder: '请输入',
    },
    select: {
      placeholder: '请选择',
    },
    aggColumn: {
      placeholder: '选择',
    },
  },
  aggType: {
    placeholder: '聚合',
    mode: {
      custom: '使用表达式',
      column: '使用字段',
    },
  },
  reset: {
    error: '数据获取失败',
    button: {
      reset: '刷新',
    },
  },
  autoBusinessCaliberAtomic: (
    datasetName: string,
    columnDisplayName: string,
    aggType: string,
  ) => {
    return `对${datasetName}的${columnDisplayName}进行${aggType}`;
  },
  autoBusinessCaliberDerived: (
    periodName: string,
    atomicMetricName: string,
    dimensionNames: string[],
  ) => {
    return `统计${periodName}的${atomicMetricName}，使用${dimensionNames.join(
      '、',
    )}进行筛选`;
  },
  upload: {
    button: '批量上传指标',
    modal: {
      title: '批量上传指标',
      downloadTemplateDesc: '下载批量上传指标模板:',
      downloadTemplate: '下载模板',
      uploadFile: '点击上传',
      uploadDesc: '或 拖放文件到此处',
      uploadingText: '正在上传文件数据...',
      cancelUploading: '取消上传',
      cancel: '取消',
      create: '创建',
      success: '创建成功',
      fileName: '创建指标草稿模板',
      errorMsg: '上传文件失败',
      retry: '重新上传',
    },
  },
  metricCategoryRouter: {
    more: '更多',
    refresh: '刷新',
    rename: '重命名',
    delete: '删除',
    moveCategory: '移动类目',
    authManage: '权限管理',
    all: (type: string) => `全部${type}`,
    search: '指标编码、展示名、名称',
    category: '类目',
    metric: '指标',
    createMore: '创建',
    createSubCategory: '新建子类目',
    createMetric: '新建指标',
  },
};

export const preview = {
  metricPicker: {
    derivate: '快速衍生',
    format: '展示格式',
    noDerivate: '无',
    samePeriod: '同环比',
    self: '当前指标',
  },
  previewData: '预览数据',
  filterDataTypeChanged: '筛选条件对应的维度的数据类型已发生变化，请重新配置',
};

export const metic = {
  detail: {
    setOffLine: '下线',
  },
};
