import { ESign, IIncrementRefreshScope } from '@/typings/dataSource';

export const materializedTable = {
  name: '名称',
  materializedTable: '物化表',
  latestData: '最新数据',
  lastUpdate: '最近更新',
  storage: '存储',
  materializedSolution: '物化方案',
  operation: '操作',
  dataset: '数据集',
  reRun: '重跑',
  refresh: '刷新',
  setSuccess: '置为成功',
  lengthOfMaterializedTable: (num: number) => `${num}个物化表`,
  noMaterializedTable: '未找到物化表信息',
  list: {
    owner: '负责人',
    updateRange: '更新范围',
  },
  detail: {
    code: '编码',
    to: '至',
    updateData: '更新数据',
    summary: '物化表详情',
    partition: '分区',
    updateRecord: '更新记录',
    updateDAG: '更新DAG',
    updateTime: '更新时间',
    field: '字段',
    metricDimension: '指标/维度',
    datasetField: '数据集字段',
    dataRange: '数据范围',
    instanceId: '实例 ID',
    status: '状态',
    updatePartition: '更新分区',
    startTime: '开始时间',
    endTime: '结束时间',
    duration: '耗时',
    resourceWaitTime: '资源等待耗时',
    dataUpdate: '数据更新',
    manualRefresh: '数据回补',
    detail: '详情',
    terminate: '终止',
    more: '更多',
    success: '成功',
    fail: '失败',
    processing: '进行中',
    init: '初始化',
    pending: '待运行',
    terminated: '已终止',
    latestPartition: '最新分区',
    basicProperty: '基础属性',
    name: '名称',
    type: '类型',
    owner: '负责人',
    dataUpdateTime: '数据更新时间',
    viewDetail: '查看详情',
    overview: '概览',
    operateSuccess: '操作成功',
  },
  updateModal: {
    desc: '系统根据数据源表的数据更新内容，将物化表数据增量更新至最新。',
    desc2: '系统会全量更新该物化表数据',
    updateType: '更新方式',
    onlyUpdateCurrentMaterializedTable: '仅更新当前物化表',
    updateCurrentAndDownstreamMaterializedTable: '更新当前及其下游物化表',
    refreshContent: '回补内容',
    updateSuccess: '更新成功',
  },
  dag: {
    detail: {
      showDetail: '查看详情',
      overview: '概览',
      setting: '设置',
    },
    current: '当前数据',
    latestData: '最新数据',
  },
};

export const taskModal = {
  instanceDetail: '实例详情',
  preview: '概览',
  executeSql: '执行 SQL',
  taskLog: '任务日志',
  basicProperty: '基础属性',
  name: '名称',
  type: '类型',
  copySuccess: '复制成功',
  copy: '复制',
};

export const editModal = {
  layout: {
    previous: '上一步',
    next: '下一步',
    editPlan: '配置方案',
    previewPlan: '预览方案',
    cancel: '取消',
    save: '保存',
  },
  step1: {
    displayName: '展示名',
    name: '名称',
    status: {
      new: '新建',
      unModified: '未修改',
      modified: '已修改',
    },
    displayNamePlaceholder: '请输入展示名',
    namePlaceholder: '请输入名称',
    dimension: '维度',
    metric: '指标',
    field: '字段',
    metricPlan: '指标加速',
    fieldPlan: '明细加速',
    dataGranularity: (dimListStr: string) => `数据粒度：${dimListStr}`,
    nameTip: '使用该名称创建物化表，创建后不支持修改。',
    comma: '，',
    copy: '复制',
    remove: '删除',
    errors: {
      columnIsRequired: '请选择字段',
      currDsColumnIsRequired: '至少选择一个当前数据集的字段',
      partitionColumnIsRequired: (
        datasetDisplayName: string,
        columnDisplayNames: string[],
      ) =>
        `请选中当前数据集[${datasetDisplayName}]所有的必选字段：${columnDisplayNames.join(
          '、',
        )}`,
      metricIsRequired: '请选择指标',
      dimensionNotFound: '存在不可用的维度',
      metricNotFound: '存在不可用的指标',
      columnNotFound: '存在不可用的字段',
      nameIsRequired: '请输入名称',
      nameFormatError: '名称只能包含字母、数字、下划线，且以字母开头',
      duplicatedName: '名称不能和其他方案重复',
      displayNameIsRequired: '请输入展示名',
      duplicatedDisplayName: '展示名不能和其他方案重复',
      aggPlansConflict: ' 方案选择的数据粒度相同，请修改方案。',
      rawPlansConflict: ' 方案选择的字段相同，请修改方案。',
    },
  },
  step2: {
    more: '更多',
    plan: '方案',
    recommendTab: '智能优化',
    recommend: '建议',
    accepted: '已采纳',
    denied: '已拒绝',
    unHandled: '未处理',
    accept: '采纳',
    noRecommend: '暂无需要优化的建议',
    deny: '拒绝',
    unhandledError: '请处理智能优化建议',
    waitForPreviewData: '预览数据尚未加载完成',
    renameModalTitle: '编辑',
    fullRefresh: '全量更新',
    incrementalRefresh: '增量更新',
  },
};

export const plan = {
  multidimensional: '指标/明细加速',
  deleteSuccess: '删除成功',
  deleteModal: {
    title: '删除方案',
    desc: '您确定要删除此方案吗？删除后将无法恢复！',
    cancel: ' 取消',
    delete: '删除',
  },
  application: '结果加速',
  title: '物化方案',
  create: '新建物化方案',
  datasetPickModal: {
    desc: '选择数据集，为该数据集下的指标创建物化加速方案。',
  },
  count: (count: number, isMultiDimension: boolean = true) =>
    `${count} 个${isMultiDimension ? '指标/明细加速' : '结果加速'}`,
  copy: '复制',
  makeUpData: '回补数据',
  edit: '编辑',
  delete: '删除',
  showMaterializedTable: (count: number) => `查看物化表(${count})`,
  type: {
    all: '全部',
    raw: '明细加速',
    agg: '指标维度组合',
  },
  lastRefreshTime: '最新数据 ',
  refreshPlanModal: {
    title: '回补数据',
    metric: '指标',
    range: '回补范围',
    type: {
      title: '回补方式',
      upStream: '同时回补上游依赖',
      directFromSourceTable: '直接从数据源表获取数据回补',
    },
    warning: (metricNames: string[]) =>
      `${metricNames.map((name) => `[${name}]`).join(' ')} 指标为全量更新`,
    rangeEmptyMsg: '回补范围不能为空',
    metricsEmptyMsg: '指标不能为空',
    success: '提交成功',
    successDesc:
      '回补任务已提交成功，您可以跳转到回补任务详情查看具体内容，或直接关闭此对话框。',
    okText: '查看回补任务详情',
    cancelText: '知道了',
    content: {
      title: '回补内容',
      desc: '系统会依次更新以下物化表的对应数据内容',
      fullRefresh: '全量更新',
      tableColumns: {
        mtTable: '物化表',
      },
    },
  },
  owner: (nickname: string) => '负责人: ' + nickname,
  refreshContent: (
    lowerIncrementRefreshScope: IIncrementRefreshScope,
    upperIncrementRefreshScope: IIncrementRefreshScope,
    granularityText: string,
  ) =>
    `更新内容: 更新${
      lowerIncrementRefreshScope.interval === 0
        ? `当${granularityText}`
        : `${lowerIncrementRefreshScope.interval}${granularityText}${
            lowerIncrementRefreshScope.sign === ESign.NEGATIVE ? '前' : '后'
          }`
    }至${
      upperIncrementRefreshScope.interval === 0
        ? `当${granularityText}`
        : `${upperIncrementRefreshScope.interval}${granularityText}${
            upperIncrementRefreshScope.sign === ESign.NEGATIVE ? '前' : '后'
          }`
    }的数据内容`,
  refreshContent1: (granularityText: string) =>
    `更新内容: 更新当${granularityText}的数据内容`,
  refreshContent2: '更新内容: 全量更新',
  refreshCycle: (isScheduleUpdate: boolean, cronString: string) =>
    `更新周期: ${isScheduleUpdate ? `${cronString}` : '依赖触发更新'}`,
  dataset: (displayname: string) => '数据集: ' + displayname,
};

export const manualRefreshTask = {
  title: '补数方案',
  count: (count: number) => `${count}个补数方案`,
  refresh: '刷新',
  tableColumns: {
    manualRefreshTask: '补数任务',
    status: '状态',
    statusText: {
      init: '初始化',
      fail: '补数异常',
      success: '成功',
      running: '补数中',
    },
    timeRange: '补数范围',
    fullRefresh: '全量更新',
    incrementalRefresh: '增量更新',
    mtTask: '物化方案',
    metric: '指标',
    emptyMetric: '无',
    dataset: '数据集',
    operate: {
      title: '操作',
      detail: '详情',
    },
  },
};

export const accelerateResult = {
  errorTags: '发生了错误',
  filter: {
    showDisplayCode: '展示指标/维度编码',
    showName: '展示指标/维度名称',
  },
  editModal: {
    title: (isEdit: boolean) => (!isEdit ? '新建结果加速' : '编辑结果加速'),
    cancelBtn: '取消',
    saveBtn: '保存',
    form: {
      title: {
        baseInfo: '基本信息',
        accelerateContent: '加速内容',
        refreshConfig: '更新设置',
        notify: '通知',
      },
      saveSuccess: '保存成功',
      label: {
        displayname: '展示名',
        name: '名称',
        accelerateGroup: '加速内容',
        accelerateMetrics: '指标',
        accelerateDimension: '维度',
        dimensionFilter: '筛选',
        metricTimeFilter: '指标日期',
        refreshContent: '更新方式',
        refreshCycle: '更新周期',
        advancedSetting: '高级设置',
        notifySuccess: '更新成功',
        notifyError: '更新失败',
        notifyDelay: '延迟更新',
        showCode: '展示指标/维度编码',
        showName: '展示指标/维度名称',
      },
      refreshContentText: {
        prefix: (text: string) => `更新 T (${text})`,
        to: (text: string) => `${text}至 T (${text})`,
        suffix: (text: string) => `${text}的数据内容`,
        schedule: '定时更新',
        dependency: '依赖触发更新',
        desc: '定时更新加速数据',
        fullRefresh: '全量更新',
        incrementalRefresh: '增量更新',
      },
      advancedSettingText: (show: boolean) => (show ? '收起' : '展开'),
      advancedSetting: {
        failTactics: {
          label: '失败策略',
          retry: '重试',
          terminate: '终止',
        },
        retryCount: '重试次数',
        interval: '重试时间间隔',
        intervalSuffix: '分钟',
      },
      refreshDesc: '指标结果加速根据数据源表的更新情况，自动进行数据更新',
      pushChannel: {
        add: '添加渠道',
        title: (index: number) => `渠道${index}`,
      },
      filterDesc: '满足以下条件',
      notify: {
        finishTime: '计划完成时间',
      },
      getPlaceholder: (text: string) => `请输入${text}`,
      validate: {
        metricRepeat: (text: string) => `指标 ${text} 定义重复，请检查`,
        metricNotEmpty: '指标不能为空',
        dimensionNotEmpty: '维度不能为空',
        pushChannelNotEmpty: '请选择一个渠道',
        refreshContentNotEmpty: '更新内容不能为空',
        refreshContentInterval: '左侧时间需要小与右侧时间',
      },
    },
  },
  refreshData: {
    title: '回补数据',
    range: '回补范围',
    successMsg: '回补请求已发起',
  },
};
