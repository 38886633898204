export enum EFormulaType {
  // 列引用
  COLUMN = 'NAME_REF',
  // 空列
  EMPTY = 'EMPTY',
  // 常量
  CONSTANT = 'CONSTANT',
  // 二元操作
  BINARY_OPERATOR = 'BIN_OP',
  // 一元操作
  UNARY_OPERATOR = 'UN_OP',
  // 括号
  PARENTHESIS = 'PAREN',
  // 函数
  FUNCTION = 'CALL_OP',
  // 不完整的公式
  PARTIAL = 'PARTIAL',
  // 表引用
  TABLE_NAME_REF = 'TABLE_NAME_REF',
}

// 二元运算符

export enum EBinaryOperator {
  PLUS = '+',
  MINUS = '-',
  MULTIPLE = '*',
  DIVISION = '/',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  EQUAL = '=',
  NOT_EQUAL = '<>',
  GREATER_THAN_EQUAL = '>=',
  LESS_THAN_EQUAL = '<=',
  AND = 'AND',
  OR = 'OR',
}

// 一元运算符
export enum EUnaryOperator {
  MINUS = '-',
  NOT = 'NOT',
}

export interface IFormulaPosition {
  start: number;
  line: number;
  text: string;
}
// 用来存储需要特殊处理的数据
export interface IFormulaOtherInfo {
  errorMsg?: string;
  isChildrenHasOne2manyDatasetColumn?: boolean;
  isDimension?: boolean;
  /**
   * 用来存储节点的位置信息，有以下规则
   * type为function的时候，存储functionName、左括号、右括号的位置
   * type为parenthesis的时候，存储左括号和右括号的位置
   * 其他的type存储本身的位置
   *  */
  position?: IFormulaPosition[];
}
export interface IColumnFormula extends IFormulaOtherInfo {
  type: EFormulaType.COLUMN;
  path: string[];
}

export interface ITableFormula extends IFormulaOtherInfo {
  type: EFormulaType.TABLE_NAME_REF;
  tableGuid: string;
}

export interface IEmptyFormula {
  type: EFormulaType.EMPTY;
}

export interface IConstantFormula extends IFormulaOtherInfo {
  type: EFormulaType.CONSTANT;
  val: IConstantNumberVal | IConstantStringVal | IConstantBooleanVal;
}

export interface IOperatorFormula extends IFormulaOtherInfo {
  type: EFormulaType.BINARY_OPERATOR;
  op: EBinaryOperator;
  x: TCorrectFormula;
  y: TCorrectFormula;
}

export interface IParenthesisFormula extends IFormulaOtherInfo {
  type: EFormulaType.PARENTHESIS;
  x: TCorrectFormula;
}

export interface IUnaryOperator extends IFormulaOtherInfo {
  type: EFormulaType.UNARY_OPERATOR;
  op: EUnaryOperator;
  x: TCorrectFormula;
}

export interface IFunctionOperator extends IFormulaOtherInfo {
  type: EFormulaType.FUNCTION;
  op: string;
  args: TCorrectFormula[];
}

export interface IPartialFormula extends IFormulaOtherInfo {
  type: EFormulaType.PARTIAL;
  text: string;
}

export enum EExtTypeEnum {
  USER_PROPERTY = 'USER_PROPERTY',
}

export interface IUserConstantFormula extends IConstantFormula {
  extType: EExtTypeEnum;
}

export type TCorrectFormula =
  | IColumnFormula
  | ITableFormula
  | IConstantFormula
  | IOperatorFormula
  | IParenthesisFormula
  | IUnaryOperator
  | IFunctionOperator;

export type TFormula =
  | IColumnFormula
  | ITableFormula
  | IEmptyFormula
  | IConstantFormula
  | IOperatorFormula
  | IParenthesisFormula
  | IUnaryOperator
  | IFunctionOperator
  | IPartialFormula
  | IUserConstantFormula;

export interface IConstantNumberVal {
  type: EOriginDataType.DOUBLE | EOriginDataType.BIGINT;
  val: string;
}

export interface IConstantStringVal {
  type: EOriginDataType.VARCHAR;
  val: string;
}

export interface IConstantBooleanVal {
  type: EOriginDataType.BOOLEAN;
  val: boolean;
}

export enum EException {
  // 推导异常
  DERIVATE = 'DERIVATE',
  // 无法解析
  INVALID_FORMULA = 'INVALID_FORMULA',
  // 循环引用
  CYCLE_REFERENCE = 'CYCLE_REFERENCE',
  // 依赖字段不存在
  REFERENCE_NOT_EXIST = 'REFERENCE_NOT_EXIST',
  // 名称校验错误
  NAME_FORMAT_ERROR = 'NAME_FORMAT_ERROR',
}

// presto type
export enum EOriginDataType {
  TINYINT = 'TINYINT',
  SMALLINT = 'SMALLINT',
  INTEGER = 'INTEGER',
  INT = 'INT',
  BIGINT = 'BIGINT',

  BOOLEAN = 'BOOLEAN',

  FLOAT = 'FLOAT',
  REAL = 'REAL',
  DOUBLE = 'DOUBLE',

  DECIMAL = 'DECIMAL',

  VARCHAR = 'VARCHAR',
  VARBINARY = 'VARBINARY',
  CHAR = 'CHAR',
  TABLE = 'TABLE',
  LIST = 'LIST',
  STRING = 'STRING',

  DATE = 'DATE',

  DATETIME = 'DATETIME',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  TIME_WITH_TIME_ZONE = 'TIME WITH TIME ZONE',
  TIMESTAMP_WITH_TIME_ZONE = 'TIMESTAMP WITH TIME ZONE',
  INTERVAL_YEAR_TO_MONTH = 'INTERVAL YEAR TO MONTH',
  INTERVAL_DAY_TO_SECOND = 'INTERVAL DAY TO SECOND',
  TIMESTAMP_NTZ = 'TIMESTAMP_NTZ',

  JSON = 'JSON',
  VARIANT = 'VARIANT',
  STRUCT = 'STRUCT',
  MAP = 'MAP',
  ARRAY = 'ARRAY',
}

export enum EQueryEngineType {
  PRESTO = 'PRESTO',
  AIR = 'AIR',
  MAXCOMPUTE = 'MAXCOMPUTE',
  STARROCKS = 'STARROCKS',
  DORIS = 'DORIS',
  DATABRICKS = 'DATABRICKS',
}
