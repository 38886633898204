// 用户的角色列表
export enum EUserRole {
  OWNER = 'OWNER', // 负责人
  ADMIN = 'ADMIN', // 管理员
  USAGER = 'USAGER', // 使用者
  ANALYST = 'ANALYST',
  VIEWER = 'VIEWER', // 查看者
}
//资源角色
export enum EResourceRole {
  OWNER = 'OWNER', // 负责人
  ADMIN = 'ADMIN', // 管理员
  USAGER = 'USAGER', // 使用者
  VIEWER = 'VIEWER', // 查看者
}
// 平台角色
export enum ETenantRole {
  OWNER = 'OWNER', // 超级管理员
  ADMIN = 'ADMIN', // 平台管理员
  ANALYST = 'ANALYST', // 指标定义者
  USAGER = 'USAGER', // 指标使用者
}
// 类目角色
export enum ECategoryRole {
  OWNER = 'OWNER', // 负责人
  ADMIN = 'ADMIN', // 管理员
  CREATOR = 'CREATOR', //创建者 ,可在类目下创建子类目和指标
  USAGER = 'USAGER', // 使用者
  VIEWER = 'VIEWER', // 查看者
}
export type TUserRole = EUserRole | EResourceRole | ETenantRole | ECategoryRole;
// // 用户权限列表数据返回数据
export interface IUserAuthority {
  authorityResource: IAuthorityResource;
  authorityAccount: IAuthorityAccountResponseItem;
}

export enum EResourceType {
  TENANT = 'TENANT',
  DATASOURCE = 'DATASOURCE',
  WORKBOOK = 'WORKBOOK',
  VIEW = 'VIEW',
  DATASET = 'DATASET',
  FILE = 'FILE',
  METRIC = 'METRIC',
  USER_GROUP = 'USER_GROUP',
  USER_PROPERTY = 'USER_PROPERTY',
  CATEGORY = 'CATEGORY',
  DIMENSION = 'DIMENSION',
  ANALYSIS_VIEW = 'ANALYSIS_VIEW',
  RP_TASK = 'RP_TASK',
  CATEGORY_METRIC = 'CATEGORY_METRIC',
  CATEGORY_DIMENSION = 'CATEGORY_DIMENSION',
  DIMENSION_PROPERTY = 'DIMENSION_PROPERTY',
  CATEGORY_DATASET = 'CATEGORY_DATASET',
  CATEGORY_ANALYSIS_VIEW = 'CATEGORY_ANALYSIS_VIEW',
  METRIC_PROPERTY = 'METRIC_PROPERTY',
  METRIC_MONITOR = 'METRIC_MONITOR',
  METRIC_ACCELERATE = 'METRIC_ACCELERATE',
}

export enum EAccountType {
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
}

// 服务端返回回来的授权信息
export interface IAuthorityAccountResponseItem extends IAuthorityAccount {
  account: string;
  displayName: string;
  photo: string;
}
// 用户权限相关的字段
export interface IAuthorityResource {
  resourceId: string;
  resourceType: EResourceType;
  authorityRole: EUserRole;
  authoritySource: EAuthoritySource;
  authority: IAuthority;
}

// // 用户列表的权限来源
export enum EAuthoritySource {
  DIRECT = 'DIRECT',
  EXTEND = 'EXTEND',
  EXTEND_DATASOURCE = 'EXTEND_DATASOURCE',
  EXTEND_DATABASE = 'EXTEND_DATABASE',
  EXTEND_PERSONAL_SPACE = 'EXTEND_PERSONAL_SPACE',
  EXTEND_TENANT = 'EXTEND_TENANT',
}
// 用户的权限点
export interface IAuthority {
  canEdit: boolean;
  canDelete: boolean;
  canUsage: boolean;
  canAuth: boolean; // 是否可授权给他人？true => authRoleList 为可被授权的列表
  canTransfer: boolean; //是否可以转交负责人
  canCreateResources?: EResourceType[]; // 是否可以创建资源
  authRoleList?: EUserRole[];
}

// 预请求返回的数据
export interface IPreQuery {
  id: string;
  type: string;
  hasAuthority: boolean;
  isExist: boolean;
}

export interface IAuthorityAccount {
  accountType: EAccountType;
  // 代表user的userId和userGroup的groupId
  id: string;
  displayName?: string;
}
