import { originDataType2DataTypeMap } from '@/components/Formula/constant';
import { looseDataTypeMatch } from '@/components/Formula/helper';
import t from '@/locales';
import { EOriginDataType, IFunctionOperator } from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
const checkIn = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args } = params;
  // IN函数的参数
  if (!args.length) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  // IN函数的参数类型大类必须保持一致
  if (
    looseDataTypeMatch(args.map((item) => originDataType2DataTypeMap[item]))
  ) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.BOOLEAN,
    };
  } else {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }
};
export default checkIn;
