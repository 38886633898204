import styles from './index.less';
import t from '@/locales';
import { TFilterSelectValues } from '@/components/Filter/filter';
import {
  EDependencyType,
  EFilterType,
  ELooseDataType,
  ENumberRangeOperator,
  IMatchMetricValues,
  TFilter,
  TFixedDateFilter,
  TJsonFilter,
  TNumberFilter,
  TStringFilter,
  TStringValues,
} from '@/components/NewFilter/types';
import { looseDataTypeMap } from '@/components/NewFilter/constants';
import { EDateGranularityType, EOriginDataType } from '@/typings';
import { originDataType2DataTypeMap } from '@/components/Formula/constant';
import SpecificTimeRangePicker from '../../SpecificTimeRangePicker';
import SpecificTimePicker from '../../SpecificTimePicker';
import NumberRangeInput from '../../NumberRangeInput';
import NumberInput from '../../NumberInput';
import { Input } from '@aloudata/aloudata-design';
import _ from 'lodash';
import { useMemo } from 'react';
import MatchMetric from '../../MatchMetric';
import RankPicker from '../../RankPicker';

/**
 * 值输入组件
 */
export default function ValueNode(props: IProps) {
  const { value, onChange, renderValuesPicker, originDataType, currentCode } =
    props;
  const { granularity } = value;
  const leftColumnDataType = originDataType2DataTypeMap[originDataType];
  const valueComp = useMemo(() => {
    // 公共筛选类型
    if (
      value.type === EFilterType.IS_NULL ||
      value.type === EFilterType.NOT_NULL
    ) {
      return null;
    }
    if (
      value.type === EFilterType.EQUAL ||
      value.type === EFilterType.NOT_EQUAL
    ) {
      // 值选择器
      return renderValuesPicker({
        value: value.values,
        onChange: (newValues) => onChange({ ...value, values: newValues }),
      });
    }
    const looseDataType = looseDataTypeMap[leftColumnDataType];
    if (
      looseDataType === ELooseDataType.NUMBER ||
      looseDataType === ELooseDataType.TIME ||
      looseDataType === ELooseDataType.TEXT
    ) {
      if (
        value.type === EFilterType.RANK ||
        value.type === EFilterType.RANK_DENSE ||
        value.type === EFilterType.ROW_NUMBER
      ) {
        return (
          <RankPicker
            value={value.values}
            onChange={(newValues) => onChange({ ...value, values: newValues })}
          />
        );
      }
      if (value.type === EFilterType.MATCH_METRIC) {
        const { dependency } = value;
        if (dependency.type !== EDependencyType.DIMENSION) {
          return null;
        }
        const { values } = value;
        const { name } = dependency;
        return (
          <MatchMetric
            dimensionName={name}
            values={values.values}
            currentCode={currentCode}
            metricCode={values.dependency?.code}
            onChange={(newValues: IMatchMetricValues) => {
              const newValue = _.cloneDeep(value);
              onChange({
                ...newValue,
                values: newValues,
              });
            }}
          />
        );
      }
    }
    // 日期筛选类型
    if (looseDataType === ELooseDataType.TIME) {
      const currValue = value as TFixedDateFilter;
      if (
        currValue.type === EFilterType.DATE_RANGE ||
        currValue.type === EFilterType.DATE_OUTSIDE_RANGE
      ) {
        // 日期范围
        return (
          <SpecificTimeRangePicker
            granularity={granularity || EDateGranularityType.DAY}
            value={[currValue.values.min.value, currValue.values.max.value]}
            onChange={(newValues) => {
              const newValue = _.cloneDeep(currValue);
              _.set(newValue, 'values.min.value', newValues[0]);
              _.set(newValue, 'values.max.value', newValues[1]);
              onChange(newValue);
            }}
          />
        );
      }
      return (
        <SpecificTimePicker
          granularity={granularity || EDateGranularityType.DAY}
          value={currValue.values.value}
          onChange={(newValues: number) => {
            const newValue = _.cloneDeep(value);
            _.set(newValue, 'values.value', newValues);
            onChange(newValue);
          }}
        />
      );
    }
    // 数字筛选类型
    if (looseDataType === ELooseDataType.NUMBER) {
      const currValue = value as TNumberFilter;
      if (
        currValue.type === EFilterType.NUMBER_RANGE ||
        currValue.type === EFilterType.NUMBER_OUTSIDE_RANGE
      ) {
        return (
          <NumberRangeInput
            value={[currValue.values.low.value, currValue.values.high.value]}
            onChange={(newValues) =>
              onChange({
                ...currValue,
                values: {
                  low: {
                    value: newValues[0],
                    operator: ENumberRangeOperator.LESS_OR_EQUAL,
                  },
                  high: {
                    value: newValues[1],
                    operator: ENumberRangeOperator.LESS_OR_EQUAL,
                  },
                },
              })
            }
          />
        );
      }
      if (
        currValue.type === EFilterType.NUMBER_EQUAL ||
        currValue.type === EFilterType.NUMBER_NOT_EQUAL
      ) {
        return renderValuesPicker({
          value: currValue.values,
          onChange: (newValues) =>
            onChange({ ...currValue, values: newValues }),
        });
      }
      return (
        <NumberInput
          value={currValue.values.value}
          onChange={(newValue) =>
            onChange({
              ...currValue,
              values: { value: newValue },
            })
          }
        />
      );
    }
    // 字符串筛选类型
    if (looseDataType === ELooseDataType.TEXT) {
      const currValue = value as TStringFilter;
      return (
        <Input
          value={(currValue.values as TStringValues).value}
          placeholder={t.components.filter.condition.textPlaceholder}
          onChange={(e) => {
            const newValue = _.cloneDeep(currValue);
            _.set(newValue, 'values.value', e.target.value);
            onChange(newValue);
          }}
        />
      );
    }
    // 布尔筛选类型不需要考虑，因为它的操作符是 equal 和 not_equal，在公共筛选类型里已经判断了
    // JSON 筛选类型
    if (looseDataType === ELooseDataType.JSON) {
      const currValue = value as TJsonFilter;
      return (
        <Input
          value={currValue.values.value}
          placeholder={t.components.filter.condition.textPlaceholder}
          onChange={(e) => {
            const newValue = _.cloneDeep(currValue);
            _.set(newValue, 'values.value', e.target.value);
            onChange(newValue);
          }}
        />
      );
    }

    return null;
  }, [
    value,
    leftColumnDataType,
    renderValuesPicker,
    onChange,
    currentCode,
    granularity,
  ]);

  return <div className={styles.wrap}>{valueComp}</div>;
}

interface IProps {
  value: TFilter;
  onChange: (value: TFilter) => void;
  renderValuesPicker: (
    valuesPickerProps: IValuesPickerProps,
  ) => React.ReactNode;
  originDataType: EOriginDataType;
  currentCode?: string;
}

export interface IValuesPickerProps {
  value: TFilterSelectValues;
  onChange: (value: TFilterSelectValues) => void;
}
